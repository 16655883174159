import React, { FC } from "react";
import styled from "styled-components";
import { Card, CardProps } from "react-bootstrap";

const Container = styled(Card)`
    background-color: #fff;
    padding: 0.7rem;
    border: none;
    border-radius: 0.3rem;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
`;

interface HCardProps extends CardProps {
    onClick?: () => void;
}

const HCard: FC<React.PropsWithChildren<HCardProps>> = ({ children, className = "", onClick }) => {
    return (
        <Container className={className} onClick={onClick} style={{ cursor: onClick ? "pointer" : "default" }}>
            {children}
        </Container>
    );
};

export default HCard;
