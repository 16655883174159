import React from "react";
import style from "./ProductStock.module.scss";
import { FaCircle } from "react-icons/fa";

type ProductStockProps = {
    availableQty: number;
    availableToOrder: boolean;
};

const ProductStock: React.FC<ProductStockProps> = ({ availableQty, availableToOrder }) => {
    let stockElement;

    if (availableToOrder && availableQty === 0) {
        stockElement = (
            <div className={style.onOrder}>
                <FaCircle className="me-2" /> Disponibil pe comanda
            </div>
        );
    } else if (!availableToOrder && availableQty === 0) {
        stockElement = (
            <div className={style.outOfStock}>
                <FaCircle className="me-2" /> Stoc epuizat
            </div>
        );
    } else if (availableQty === 1) {
        stockElement = (
            <div className={style.inStock}>
                <FaCircle className="me-2" /> Ultimul produs
            </div>
        );
    } else if (availableQty === 2) {
        stockElement = (
            <div className={style.inStock}>
                <FaCircle className="me-2" /> Ultimele 2 produse
            </div>
        );
    } else if (availableQty === 3) {
        stockElement = (
            <div className={style.inStock}>
                <FaCircle className="me-2" /> Ultimele 3 produse
            </div>
        );
    } else {
        stockElement = (
            <div className={style.inStock}>
                <FaCircle className="me-2" /> In stoc
            </div>
        );
    }

    return <div className={style.productStock}>{stockElement}</div>;
};

export default ProductStock;
