"use client";

import React from "react";
import SwipperCarousel from "@/components/SwipperCarousel/SwipperCarousel";
import ProductCard from "@/components/ProductCard/ProductCard";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Products } from "@/common/entities/product/Product";
import ProductsUtils, { ProductImageSizeType } from "@/common/utils/ProductsUtils";
import { SimilarProducts } from "@/common/entities/product/SimilarProducts";
import { RecommendedProducts } from "@/common/entities/product/RecommendedProducts";

interface ProductsCarouselProps {
    title: string;
    products?: Products[];
    similarProducts?: SimilarProducts[];
    recommendedProducts?: RecommendedProducts[];
}

const ProductCarousel = ({ products, title, similarProducts, recommendedProducts }: ProductsCarouselProps) => {
    let productCards;

    if (products) {
        productCards = products.map((product, index) => (
            <ProductCard
                key={product.id || index}
                imageSrc={ProductsUtils.getProductCoverImage(ProductImageSizeType.medium, product.defaultImage)}
                productName={product.name}
                availableToOrder={product?.productStock?.availableToOrder ?? false}
                stock={product.availableQuantity || 0}
                price={product.specialPriceWithVat ? product.specialPriceWithVat : product.priceWithVat}
                oldPrice={product.specialPriceWithVat ? product.priceWithVat : undefined}
                currency={"RON"}
                shortDescription={product?.shortDescription ?? ""}
                url={ProductsUtils.getProductLink(product.url, product.categories) || ""}
                displayPrice={product.productStock?.displayPrice ?? false}
            />
        ));
    }

    if (similarProducts) {
        productCards = similarProducts.map((product, index) => (
            <ProductCard
                key={product.similarProduct?.id || index}
                imageSrc={ProductsUtils.getProductCoverImage(ProductImageSizeType.medium, product.similarProduct?.defaultImage)}
                productName={product.similarProduct?.name}
                availableToOrder={product?.similarProduct?.productStock?.availableToOrder ?? false}
                stock={product.similarProduct?.availableQuantity || 0}
                price={product.similarProduct.specialPriceWithVat ? product.similarProduct.specialPriceWithVat : product.similarProduct.priceWithVat}
                oldPrice={product.similarProduct.specialPriceWithVat ? product.similarProduct.priceWithVat : undefined}
                currency={"RON"}
                shortDescription={product.similarProduct?.shortDescription}
                url={ProductsUtils.getProductLink(product.similarProduct?.url, product.similarProduct?.categories) || ""}
                displayPrice={product.similarProduct.productStock?.displayPrice ?? false}
            />
        ));
    }

    if (recommendedProducts) {
        productCards = recommendedProducts.map((product, index) => (
            <ProductCard
                key={product.recommendedProduct?.id || index}
                imageSrc={ProductsUtils.getProductCoverImage(ProductImageSizeType.medium, product.recommendedProduct?.defaultImage)}
                productName={product.recommendedProduct?.name}
                availableToOrder={product?.recommendedProduct?.productStock?.availableToOrder ?? false}
                stock={product.recommendedProduct?.availableQuantity || 0}
                price={
                    product.recommendedProduct.specialPriceWithVat ? product.recommendedProduct.specialPriceWithVat : product.recommendedProduct.priceWithVat
                }
                oldPrice={product.recommendedProduct.specialPriceWithVat ? product.recommendedProduct.priceWithVat : undefined}
                currency={"RON"}
                shortDescription={product.recommendedProduct?.shortDescription}
                url={ProductsUtils.getProductLink(product.recommendedProduct?.url, product.recommendedProduct?.categories) || ""}
                displayPrice={product.recommendedProduct.productStock?.displayPrice ?? false}
            />
        ));
    }

    const carouselBreakpoints = {
        0: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        510: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 5,
        },
        1400: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    };

    return (
        <div className="position-relative">
            <h2 className="sectionTitle mt-4">{title}</h2>
            <FaChevronLeft className="review-swiper-button-prev" />
            <FaChevronRight className="review-swiper-button-next" />
            <SwipperCarousel
                loop={false}
                breakpoints={carouselBreakpoints}
                pagination={{ clickable: true, dynamicBullets: true }}
                navigation={{
                    nextEl: ".review-swiper-button-next",
                    prevEl: ".review-swiper-button-prev",
                }}
                // @ts-ignore
                elements={productCards}
            />
        </div>
    );
};

export default ProductCarousel;
