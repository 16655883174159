"use client";

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/pagination";

import styled from "styled-components";

const SwiperCss = styled(Swiper)`
    .swiper-horizontal {
        padding-bottom: 1rem;
    }

    .swiper-pagination-bullets {
        position: relative;
        bottom: 0px;
        margin-top: 15px;

        @media (max-width: 768px) {
            width: 80px;
            height: 50px;
            padding: 20px 0;
        }
    }

    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main,
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next,
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
        @media (max-width: 768px) {
            transform: scale(1.4);
        }
    }
    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
        @media (max-width: 768px) {
            transform: scale(1);
        }
    }

    .swiper-pagination-bullet-active,
    .swiper-button-prev,
    .swiper-button-next {
        background: var(--primary-color);
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        color: #fff;
        font-size: 1rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        top: var(--swiper-navigation-top-offset, 58%);
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        right: var(--swiper-navigation-sides-offset, 0px);
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: var(--swiper-navigation-sides-offset, 0px);
        right: auto;
    }
`;

interface SwipperCarouselProps {
    slidesPerView?: number;
    spaceBetween: number;
    elements: React.ReactNode[];
    freeMode?: boolean;
    loop?: boolean;
    navigation?: boolean | { nextEl: string; prevEl: string };
    className?: string;
    pagination?: any;
    direction?: "horizontal" | "vertical";
    breakpoints?: { [size: number]: { slidesPerView: number; spaceBetween?: number } };
    autoplay?: boolean | { delay: number; disableOnInteraction: boolean };
}

const SwipperCarousel = ({
    slidesPerView = 1,
    spaceBetween = 5,
    elements,
    navigation = true,
    freeMode = true,
    loop = true,
    pagination,
    direction = "horizontal",
    className = "p-1",
    breakpoints,
    autoplay = false,
}: SwipperCarouselProps) => {
    return (
        <div>
            {elements && elements.length > 0 && (
                <SwiperCss
                    className={className}
                    modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                    freeMode={freeMode}
                    loop={loop}
                    autoplay={autoplay}
                    direction={direction}
                    navigation={
                        typeof navigation === "boolean"
                            ? navigation
                            : {
                                  nextEl: navigation.nextEl,
                                  prevEl: navigation.prevEl,
                              }
                    }
                    slidesPerView={slidesPerView}
                    spaceBetween={spaceBetween}
                    pagination={pagination}
                    breakpoints={breakpoints}
                >
                    {React.Children.toArray(elements).map((element, index) => (
                        <SwiperSlide key={index}>{element}</SwiperSlide>
                    ))}
                </SwiperCss>
            )}
        </div>
    );
};

export default SwipperCarousel;
