"use client";

import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import HCard from "../Card";

interface LoadingProductsProps {
    rows: number;
    cols: number;
    className?: string;
}

const LoadingProducts = ({ rows = 1, cols = 1, className = "mb-5" }: LoadingProductsProps) => {
    return (
        <>
            {Array.from({ length: rows }).map((_, rowIndex) => (
                <Row key={rowIndex} className={className}>
                    {Array.from({ length: cols }).map((_, colIndex) => (
                        <Col key={colIndex}>
                            <HCard>
                                <Skeleton height={200} width={200} />
                                <Skeleton height={24} style={{ marginTop: 22 }} />
                                <Skeleton height={20} style={{ marginTop: 10, marginBottom: 22 }} />
                                <Skeleton height={14} count={3} style={{ marginTop: 4 }} />
                            </HCard>
                        </Col>
                    ))}
                </Row>
            ))}
        </>
    );
};

export default LoadingProducts;
